import React from 'react'
// eslint-disable-next-line no-unused-vars
import {useLocation, useNavigate} from 'react-router-dom'
import {scrollIntoView} from '../utils/scroll_into_view'

const CustomGlobalButton = ({
	text = 'Get mine now',
	className = '',
	gallery = false,
	galleryPremium = false,
	hero = false,
	contact = false,
	onClick = null,
	getItem = null,
	from = null,
	...props
}) => {
	const navigate = useNavigate()
	const gotoContact = () => {
		scrollIntoView(null, '#contact')
		// Clear the hash by navigating to the same path without the hash
		navigate(`/contact${getItem ? `?get=${getItem}` : ''}${from ? `&from=${from}` : ''}`, {replace: true})
	}
	return (
		<>
			<button
				onClick={onClick ? onClick : gotoContact}
				{...props}
				className={`p-4 rounded-full font-lucky text-center 
					appearance-none select-none cursor-pointer 
						transition-all duration-200
						text-white
						outline-2 |outline
						|hover:outline |hover:outline-2
						|hover:outline-offset-4 |hover:bg-opacity-30
						|focus-visible:outline 
						|focus-visible:outline-2
		 			${
							gallery
								? 'outline-e_blue bg-e_blue hover:outline'
								: galleryPremium
								? 'outline-white bg-white bg-opacity-40 hover:outline'
								: hero
								? 'bg-e_red |outline-e_red |hover:outline-white'
								: contact
								? 'bg-black outline-black text-black hover:text-black hover:bg-white'
								: 'bg-purple-600 text-white'
						} 
					
					${className}
					`}>
				{text}
			</button>
		</>
	)
}

export default CustomGlobalButton
