import React, {useCallback, useState} from 'react'
import {BiMessageDetail} from 'react-icons/bi'
import {FaInstagram, FaTelegram} from 'react-icons/fa'
import {MdOutlineMarkEmailRead} from 'react-icons/md'
import {SecretaryStandby, SendNote, TakingNotes} from '../../images'
import ContactBGPNG from '../../images/contact/ContactBG.png'
import ContactBGWebP from '../../images/contact/ContactBG.webp'
// import '../../styles/animation.css' // Fixed import statement for animation.css
import './contact.css'
import {FormContainer} from './contactForm'
import './contactPref.css'
import ScrollToTop from '../../utils/scrollToTop'
import '../../styles/animations.css'
// eslint-disable-next-line no-unused-vars
import {useLocation, useNavigate} from 'react-router-dom'
// import DividerWave from '../../../components/divider_wave/divider_wave'
// MdAlternateEmail
// import {useWhyDidYouUpdate} from '../../../hooks/useWhyDidYouUpdate'

const contact_text = "Let's chat and bring your vision to life! Just fill out this shiny form to get the ball rolling."

// eslint-disable-next-line no-unused-vars
const FieldOptions = ({setPref}) => {
	return (
		<fieldset name='pref_method' className='flex-to-center p-4' defaultValue='' onChange={setPref}>
			{/* <legend>Choose a Contact method:</legend> */}
			<input type='radio' id='email' name='method' />
			<label htmlFor='email'>
				<MdOutlineMarkEmailRead className='icon' />
				<h6>Email</h6>
			</label>
			<input type='radio' id='instagram' name='method' />
			<label htmlFor='instagram'>
				<FaInstagram className='icon' />
				<h6>Instagram</h6>
			</label>
			<br />
			<input type='radio' id='telegram' name='method' />
			<label htmlFor='telegram'>
				<FaTelegram className='icon' />
				<h6>Telegram</h6>
			</label>
			<input type='radio' id='direct' name='method' />
			<label htmlFor='direct'>
				<BiMessageDetail className='icon' />
				{/* <MdAlternateEmail className='icon' /> */}
				<h6>Stay on site</h6>
			</label>
		</fieldset>
	)
}

const Contact = () => {
	// const navigate = useNavigate()
	// const location = useLocation()

	const id = 'contact'

	const [selectedSubject, setSelectedSubject] = useState(false)

	// eslint-disable-next-line no-unused-vars
	const [messageSent, setMessageSent] = useState(false)

	const [isTyping, setIsTyping] = useState(false)

	// const [blurClass, setBlurClass] = useState('backdrop-blur-0') // Initial blur class

	// useEffect(() => {
	// 	navigate(location.pathname + location.search, {replace: true})
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [])

	const handleFocus = () => {
		setIsTyping(true)
	}
	const handleBlur = () => {
		setTimeout(() => setIsTyping(false), 500)
	}
	const handleFormChange = (event) => {
		handleFocus()
		setTimeout(() => handleBlur, 1500)
		if (event.target.name === 'subject') setSelectedSubject(true)
	}

	const SecretaryImage = useCallback(
		({className}) => {
			if (!selectedSubject)
				return (
					<div className={className}>
						<SecretaryStandby />
					</div>
				)
			else if (messageSent)
				return (
					<div className={className}>
						<SendNote />
					</div>
				)
			else {
				return (
					<div id='writingSec' className={className}>
						<TakingNotes />
					</div>
				)
			}
		},
		[selectedSubject, messageSent]
	)
	return (
		<>
			<div className='fixed inset-0 z-0 h-screen'>
				<picture>
					<source srcSet={ContactBGWebP} type='image/webp' />
					<img src={ContactBGPNG} alt='Contact Background' className='  w-full h-full object-cover object-center' />
				</picture>
			</div>
			<section
				className={`fade-this relative bg-offwhite flex-to-center flex-col overflow-hidden max-w-[1440px] mx-auto min-h-[85vh] z-10`}
				id={id}
				data-section={id}>
				<ScrollToTop />

				<div className={`fade-this flex-to-center flex-col pt-44 sm:pt-32 md:pt-20`}>
					{/* Add transition classes here */}
					<h1
						className={`text-5xl text-center lg:text-left p-6 pb-3 transition-all duration-500 backdrop-blur-0 ${'blurClass'} backdrop-blur-sm text-black font-lucky bg-white/80  rounded-lg z-10 my-12`}>
						Contact
					</h1>
					<div
						className={`relative max-w-6xl flex-to-center flex-col transition-all duration-500 backdrop-blur-0 ${'blurClass'} backdrop-blur-sm md:flex-row bg-white bg-white/80 rounded-lg z-10 px-6 py-10 mb-12 mx-8`}>
						<div className='form_section transition-all duration-[5s]'>
							{!messageSent && <p className='text-md text-black mb-8'>{contact_text}</p>}
							<FormContainer
								contactMethod={selectedSubject}
								onChange={handleFormChange}
								setMessageSent={setMessageSent}
								messageSent={messageSent}
								handleFocus={handleFocus}
								handleBlur={handleBlur}
							/>
						</div>
						<div className='hidden md:flex self-end relative pt-6 md:pt-0 max-w-[50vw]'>
							<SecretaryImage className={`doodle fill-black stroke-black z-10 h-[40rem] w-auto ${isTyping ? ' drawing' : ''}`} />
							{/* min-w-["min(200px, 30vw)"] */}
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default Contact
