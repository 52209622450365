import React, {useContext, useEffect} from 'react'
import {LoadingContext, NavContext} from '../index'
import {Image} from 'antd' // Import Image component from antd

const GalleryImage = ({src, previewSrc}) => {
	return (
		<picture>
			<source srcSet={src.webp} type='image/webp' media='(max-width: 600px)' />
			<source srcSet={src.jpg} type='image/jpeg' media='(max-width: 600px)' />
			<source srcSet={previewSrc.webp} type='image/webp' media='(min-width: 601px)' />
			<source srcSet={previewSrc.jpg} type='image/jpeg' media='(min-width: 601px)' />
			<Image
				preview={{visible: false}} // Disable default preview
				src={src.thumbnail} // Use thumbnail for display
				onClick={() => Image.preview({src: previewSrc.large})} // Open larger image on click
			/>
		</picture>
	)
}

const GalleryImages = ({images}) => {
	return (
		<div>
			{images.map((image, index) => (
				<GalleryImage
					key={index}
					src={{
						thumbnail: image.thumbnail,
						webp: image.webp,
						jpg: image.jpg,
					}}
					previewSrc={{
						large: image.large,
						webp: image.largeWebp,
						jpg: image.largeJpg,
					}}
				/>
			))}
		</div>
	)
}

const Test = () => {
	// eslint-disable-next-line no-unused-vars
	const {setIsLoaded, isLoaded} = useContext(LoadingContext) // Use the context
	const {setHideOnScroll, setOnHeroSection} = useContext(NavContext) // Access the context

	useEffect(() => {
		// console.log('useEffect triggered on Test, isLoaded:', isLoaded)

		setIsLoaded(true)
		setHideOnScroll(true)
		setOnHeroSection(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className='min-h-screen text-black mt-44'>
			<h1 className=''>test page</h1>
			<GalleryImages images={[]} />
		</div>
	)
}

export default Test
